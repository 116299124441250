import languages from "@/languages.json";

export default () => ({
  getLangFromPath(path) {
    const lang = path.substr(1, 2);
    return languages.some((value) => lang === value.iso) ? lang : "";
  },
  getPathWithOutLang(path) {
    return `/${path
      .split("/")
      .filter((item) => item.length > 0 && item !== this.getLangFromPath(path))
      .join("/")}`;
  },
});
