
import lang from "@/mixins/lang.js";
import device from "~/mixins/device.js";
export default {
  layout: "default",
  name: "Header",
  mixins: [lang, device],
  data() {
    return {
      header: {},
      navigation: {},
      contactInfo: {},
    };
  },
  async fetch() {
    try {
      const { data } = await this.$axios.get(
        `site-settings?_locale=${this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : 'en'
        }`
      );
      this.header = data.headerSettings;
      this.contactInfo = data.contactInfo;
      const dataNavigation = await this.$axios.get(
        `navigation?_locale=${this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : 'en'
        }`
      );
      this.navigation = dataNavigation.data.topNavigation;
    } catch (ex) { }
  },
  methods: {
    navMenuOpen() {
      if (
        document.querySelector("html").classList.value == "" &&
        this.isMobile
      ) {
        document.querySelector("html").classList.add("overflow-hidden");
      } else {
        document.querySelector("html").classList.remove("overflow-hidden");
      }
    },
    navMenuClose() {
      if (this.isMobile) {
        document.getElementById("navbar-toggler").click();
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
};
