const MOBILE_WIDTH = 1200;

export default {
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    this.checkWidth();
    window.addEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      this.isMobile = window.innerWidth < MOBILE_WIDTH;
    }
  }
};
