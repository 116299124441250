import languages from "@/languages.json";

export default {
  data() {
    return {
      path: this.$utils.helper.getPathWithOutLang(this.$route.path),
      activeLang: this.$utils.helper.getLangFromPath(this.$route.path),
      browserLang: '',
      defaultLang: 'en'
    }
  },
  beforeMount() {
    languages.filter((item) => {
      this.browserLang = item.iso === navigator.language ? item.iso : ''
    })
  },
  computed:{
    currentLang(){
       return this.activeLang && this.activeLang.length > 0 && this.activeLang !== 'en'
          ? this.activeLang
          : this.browserLang && this.browserLang.length > 0 && this.browserLang !== 'en'
          ? this.browserLang
          : ''
    },
    switchLangPath() {
      return this.activeLang && this.activeLang === 'en' ? 'tr' : this.activeLang === 'tr' ? '' : this.browserLang && this.browserLang === 'en' ? 'tr' : this.browserLang === 'tr' ? '' : 'tr'
    },
    switchLang() {
      return this.activeLang && this.activeLang === 'en' ? 'tr' : this.activeLang === 'tr' ? 'en' : this.browserLang && this.browserLang === 'en' ? 'tr' : this.browserLang === 'tr' ? 'en' : 'tr'
    },
  }
}