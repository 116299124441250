import Helper from "./helper";

export default (context, inject) => {
  // Initialize API factories
  const factories = {
    helper: Helper(context.i18n),

  };

  // Inject $api
  inject("utils", factories);
};
