
import lang from "~/mixins/lang.js";
export default {
  layout: "default",
  mixins: [lang],
  data() {
    return {
      logo: {},
      footerMenuFirst: {},
      footerMenuLast: {},
      footer: {},
      contactInfo: {},
    };
  },
  async fetch() {
    try {
      const { data } = await this.$axios.get(
        `site-settings?_locale=${this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : 'en'
        }`
      );
      this.logo = data.headerSettings.logo;
      this.footer = data.footerSettings;
      this.contactInfo = data.contactInfo;

      const dataFooterMenu = await this.$axios.get(
        `navigation?_locale=${this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : 'en'
        }`
      );
      const totalAverage = Math.ceil(
        dataFooterMenu.data.footerNavigation.link.length / 2
      );
      this.footerMenuFirst = dataFooterMenu.data.footerNavigation.link.splice(
        0,
        totalAverage
      );
      this.footerMenuLast = dataFooterMenu.data.footerNavigation.link.splice(
        0,
        totalAverage
      );
    } catch (ex) { }
  },
};
