
import device from "~/mixins/device.js";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "default",
  mixins: [device],
  mounted() {
    window.addEventListener("scroll", this.scroll);
    let durationValue = this.isMobile ? 400 : 800;
    let offsetValue = this.isMobile ? 0 : 120;
    let anchorPlacementValue = this.isMobile ? "bottom-bottom" : "top-bottom";
    AOS.init({
      duration: durationValue,
      mirror: true,
      once: false,
      offset: offsetValue,
      anchorPlacement: anchorPlacementValue,
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
    AOS.destroy();
  },
  methods: {
    scroll() {
      let scrollPosition = window.scrollY;
      let header = document.querySelector(".header");
      if (scrollPosition > 350) {
        header.classList.add("sticky");
      } else {
        AOS.refresh();
        header.classList.remove("sticky");
      }
    },
  },
};
